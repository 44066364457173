function slide_minim(target){
  var items = target;
//  $("#fade_text div");
  var n = 0;
  var timeroop;
  var infinite = true;
  var length = 2;
  var speed = 5000;
  var animationSpeed = 1800;
  var animation = "fadeIn";

  function roops(){
    if((infinite === true) && n >= length){
//      $(items[n]).removeClass("animated active " + animation);
//      $(items[0]).addClass("animated active " + animation);
      $(items[0]).fadeIn(animationSpeed);
      $(items[n]).fadeOut(animationSpeed);
      n = 0;
      timeroop = window.setTimeout(roops,speed);
    } else if(n > length){
      window.clearTimeout(timeroop);
    } else {
//      $(items[n]).removeClass("animated active " + animation);
//      $(items[n+1]).addClass("animated active " + animation);
      $(items[n+1]).fadeIn(animationSpeed);
      $(items[n]).fadeOut(animationSpeed);
      n++;
//      timeroop = window.setTimeout(roops,speed);
    }
  }
  $(items[0]).fadeIn();
  window.setTimeout(roops,speed);
}

function no_scroll(area){
  $(area).on('touchmove.noScroll', function(e) {
    e.preventDefault();
  });
}
function return_scroll(area){
  // スクロール禁止 解除
  $(area).off('.noScroll');
}

function globalmenu(){
  var body = $("div#body");
  var content = $("#cid-globalMenu");
  var position = $("body").scrollTop();

  $(".cs-toggleGlobalMenu").on("click",function(){
    $(".cs-smartmenu").css("display", "block");
    $("#cid-globalMenu").toggleClass("animated fadeInRight");
    $("#cid-globalMenu").toggleClass("animated fadeOutRight");

    if(body.hasClass("fix")){
      body.removeClass("fix");
      return_scroll(body);
      console.log(position);
      window.scroll(0,position);
    } else {
      position = $("body").scrollTop();
      body.css("top",-position + 40);
      body.addClass("fix");
      no_scroll(body);
    }

  });
  $(".item.children").on("click",function(){
    $(this).toggleClass("open");
    $(this).next().toggle();
    $(this).next().toggleClass("fadeIn");
    $(this).next().toggleClass("fadeOut");
  });
}


// Immediate function
// 即時関数
globalmenu();

$('.magnific').magnificPopup({type:'image'});

$(".button").on("click",function(){
  $("#trans").addClass("animated fadeInLeft");
});

$(".sidebarInner .children").on("click",function(){
  $(this).toggleClass("active");
});
//$(".arrow.prev").on("click",function(){
//  var pos = $("#galleyMove").css("left");
//  var posNum = parseInt(pos) / -170;
//
//  if(posNum < 1){
//    $("#galleyMove").css("left", -680);
//  } else {
//    $("#galleyMove").css("left",(parseInt(posNum) - 1) * -170);
//  }
//});
//$(".arrow.next").on("click",function(){
//  var pos = $("#galleyMove").css("left");
//  var posNum = parseInt(pos) / -170;
//
//  if(posNum >= 4){
//    $("#galleyMove").css("left", 0);
//  } else {
//    $("#galleyMove").css("left",(parseInt(posNum) + 1) * -170);
//  }
//});
// /即時関数



// init
slide_minim($("#fade_text div"));
slide_minim($("#fade_back div"));
var $windowSize = $(window).width();

$(document).ready(function(){
  $('.slicks').slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,

    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  });
});



// /init


